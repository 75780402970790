<template>
  <section id="projects">
    <transition name="bounce">
      <b-container class="block-spacing" v-if="start">
        <b-row class="spacing-small">
          <b-col cols="12">
            <h2 class="spacing-small">Projecten</h2>
            <b-row>
              <b-col cols="12" md="4">
                <h3 class="spacing-big">Projecten:</h3>
                <AnimatedCounter v-model="toNumberProjecten" />
              </b-col>
              <b-col cols="12" md="4">
                <h3 class="spacing-big">Lopende projecten:</h3>
                <AnimatedCounter v-model="toNumberLopendeProjecten" />
              </b-col>
              <b-col cols="12" md="4">
                <h3 class="spacing-big">Afgeronden projecten:</h3>
                <AnimatedCounter v-model="toNumberAfgerondenProjecten" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <h3>Bedrijven</h3>
            <VueSlickCarousel v-bind="settings">
              <a href="https://official-youngboyz.nl" target="blank">
                <img src="@/assets/images/logos/logo3.png" alt="" />
              </a>
              <a href="https://you-toys.nl" target="blank">
                <img src="@/assets/images/logos/logo2.png" alt="" />
              </a>
              <a href="https://donmerch.nl" target="blank">
                <img src="@/assets/images/logos/logo5.png" alt="" />
              </a>
              <!-- <a>
                <img src="@/assets/images/logos/logo1.png" alt="" />
              </a> -->
              <a href="https://florigreens.nl" target="blank">
                <img src="@/assets/images/logos/logo4.png" alt="" />
              </a>
            </VueSlickCarousel>
            <div class="spacing-big"></div>
          </b-col>
        </b-row>
      </b-container>
    </transition>
    <a class="bounce-7" @click="scrolling('form')"><h2>Contact</h2> </a>
  </section>
</template>

<script>
import { inView, scrolling } from "@/utils";
import VueSlickCarousel from "vue-slick-carousel";
import AnimatedCounter from "@/components/AnimatedCounter";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Projects",
  components: {
    VueSlickCarousel,
    AnimatedCounter,
  },
  data() {
    return {
      toNumberLopendeProjecten: 0,
      toNumberAfgerondenProjecten: 0,
      toNumberProjecten: 0,
      start: false,
      settings: {
        dots: true,
        infinite: true,
        initialSlide: 2,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
        ],
      },
    };
  },
  mounted() {
    const element = document.getElementById("projects");
    const elementHeight = element.clientHeight;
    window.addEventListener("scroll", () => {
      this.start = inView(element, elementHeight);
    });
  },
  watch: {
    start: function(val) {
      // console.log(val);
      this.toNumberLopendeProjecten = val ? 4 : 0;
      this.toNumberAfgerondenProjecten = val ? 4 : 0;
      this.toNumberProjecten = val ? 13 : 0;
    },
  },
  methods: {
    scrolling,
  },
};
</script>
<style lang="scss">
@import "@/assets/variables";

.slick-dots {
  li button:before,
  li.slick-active button:before {
    color: $secondary-color;
    font-size: 12px;
  }
}

.slick-slide img {
  width: 100%;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}
</style>
