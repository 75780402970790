const scrolling = (to) => {
  document.getElementById(to).scrollIntoView({ behavior: "smooth" });
};

// check if element is in view
const inView = (element, elementHeight) => {
  // get window height
  const windowHeight = window.innerHeight;
  // get number of pixels that the document is scrolled
  const scrollY = window.scrollY || window.pageYOffset;

  // get current scroll position (distance from the top of the page to the bottom of the current viewport)
  const scrollPosition = scrollY + windowHeight;
  // get element position (distance from the top of the page to the bottom of the element)
  const elementPosition =
    element.getBoundingClientRect().top + scrollY + elementHeight / 2;
  // console.log(scrollPosition + ", " + elementPosition + ", " + element);
  // is scroll position greater than element position? (is element in view?)
  if (scrollPosition > elementPosition) {
    return true;
  }

  return false;
};

export { scrolling, inView };
