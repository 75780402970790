import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import VueSplash from "vue-splash";

import VueAnalytics from "vue-analytics";
//and then use it in main.js
Vue.use(VueAnalytics, {
  id: "UA-87832041-4",
  router,
});

Vue.use(VueSplash);

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
