<template>
  <section id="services">
    <transition name="bounce">
      <b-container class="block-spacing" v-if="start">
        <b-row align-h="center">
          <b-col cols="12">
            <h2 class="spacing-smaller">Diensten</h2>
            <b-row align-h="center">
              <b-col cols="12">
                <p class="spacing-big">
                  Ik ben van mening dat service alles is. vandaar dat ik zorg
                  dat een heel pakket samen gesteld kan worden aan de hand van
                  de diensten die je hier onder kan aan klikken.
                </p>
              </b-col>
              <b-col class="spacing-big" cols="12" md="6">
                <button @click="setModalData('Website bouw')" class="pushable">
                  <span class="front">
                    Website Bouw
                  </span>
                </button>
              </b-col>
              <b-col class="spacing-big" cols="12" md="6">
                <button @click="setModalData('App bouw')" class="pushable">
                  <span class="front">
                    App Bouw
                  </span>
                </button>
              </b-col>
              <b-col class="spacing-big" cols="12" md="6">
                <button @click="setModalData('Hosting')" class="pushable">
                  <span class="front">
                    Hosting
                  </span>
                </button>
              </b-col>
              <b-col class="spacing-big" cols="12" md="6">
                <button @click="setModalData('E-mail')" class="pushable">
                  <span class="front">
                    E-mail
                  </span>
                </button>
              </b-col>
              <b-col class="spacing-big" cols="12" md="6">
                <button
                  @click="setModalData('E-mailmarketing')"
                  class="pushable"
                >
                  <span class="front">
                    E-mailmarketing
                  </span>
                </button>
              </b-col>
              <b-col class="spacing-big" cols="12" md="6">
                <button
                  @click="setModalData('Domein aanvraag')"
                  class="pushable"
                >
                  <span class="front">
                    Domein aanvraag
                  </span>
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </transition>
    <!-- Modal -->
    <b-modal centered id="modal-product" size="xl" :title="title" hide-footer>
      <b-container>
        <b-row>
          <b-col
            class="spacing-smaller"
            v-for="(block, index) in this.modalData"
            :key="index"
          >
            <b-card :title="block.title">
              <b-card-text>
                <p>{{ block.content }}</p>
                <h5 v-html="block.prijs"></h5>
                <ul v-if="block.rules">
                  <li v-for="(rule, index) in block.rules" :key="index">
                    <p v-html="rule"></p>
                  </li>
                </ul>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <a class="bounce-7" @click="scrolling('projects')"><h2>Projecten</h2> </a>
  </section>
</template>

<script>
import { inView, scrolling } from "@/utils";
import { serviceData } from "@/serviceData";

export default {
  name: "Services",
  components: {},

  props: {},
  data() {
    return {
      start: false,
      serviceData: serviceData,
      modalData: [],
      title: "",
    };
  },
  mounted() {
    const element = document.getElementById("services");
    const elementHeight = element.clientHeight;
    window.addEventListener("scroll", () => {
      this.start = inView(element, elementHeight);
    });
  },
  methods: {
    scrolling,
    setModalData(which) {
      // console.log(which);
      this.modalData = this.serviceData[which];
      this.title = which;
      this.$bvModal.show("modal-product");
    },
  },
};
</script>

<style lang="scss">
.modal {
  color: #ffffff;
  .modal-header {
    border-bottom: none;
  }
  .modal-content {
    background: $primary-color;

    .card {
      background: $third-color;
      &-body {
        text-align: center;
        h4.card-title {
          font-size: 19px;
        }
        p {
          font-size: 14px;
          margin: 1rem 0;
          span {
            font-size: 25px;
            color: $secondary-color;
          }
        }
        ul {
          padding: 0;
          margin: 0;
          li {
            list-style: none;
          }
        }
      }
    }
  }
}
</style>
