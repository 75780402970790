<template>
  <h2 id="animated">{{ animatedNumber }}</h2>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "animatedCounter",
  data() {
    return {
      fromNumber: 0,
      toNumber: 0,
    };
  },
  props: {
    value: { type: Number, default: 0, required: true },
  },
  created() {
    // console.log(this.value);
    this.toNumber = this.value;
  },
  computed: {
    animatedNumber() {
      return this.fromNumber.toFixed(0);
    },
  },
  watch: {
    toNumber: function(newValue) {
      // console.log("test: " + newValue);
      gsap.to(this.$data, { duration: 3, fromNumber: newValue });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
