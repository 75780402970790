<template>
  <section id="form">
    <transition name="bounce">
      <b-container class="block-spacing" v-if="start">
        <b-row>
          <b-col cols="12">
            <div class="form">
              <b-row>
                <b-col class="line" cols="12" lg="5">
                  <h2 class="spacing-small">Gegevens</h2>

                  <p class="spacing-smaller">
                    <i class="fas fa-envelope"></i
                    ><a href="mailto:info@tobdevelopment.nl"
                      >info@tobdevelopment.nl</a
                    >
                  </p>
                  <p class="spacing-smaller">
                    <i class="fas fa-phone"></i
                    ><a href="tel:0618695560">+31 6 18695560</a>
                  </p>
                  <p>
                    <i class="fas fa-building"></i>
                    kvk 80681778
                  </p>
                  <span class="spacing-small d-block d-lg-none"></span>
                </b-col>
                <b-col cols="12" lg="7" class="contact">
                  <h2 class="spacing-small">Contact</h2>
                  <b-form @submit="onSubmit">
                    <b-row>
                      <b-col md="6" cols="12">
                        <b-form-group id="input-group-1" label-for="input-1">
                          <b-form-input
                            id="input-1"
                            v-model="form.name"
                            required
                            placeholder="Naam*"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group id="input-group-2" label-for="input-2">
                          <b-form-input
                            id="input-2"
                            v-model="form.company"
                            placeholder="Bedrijf"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group id="input-group-3" label-for="input-3">
                          <b-form-input
                            id="input-3"
                            v-model="form.adress"
                            placeholder="Adres"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="6" cols="12">
                        <b-form-group id="input-group-4" label-for="input-4">
                          <b-form-input
                            id="input-4"
                            v-model="form.email"
                            type="email"
                            required
                            placeholder="Email*"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group id="input-group-5" label-for="input-5">
                          <b-form-input
                            id="input-5"
                            v-model="form.phone_number"
                            type="tel"
                            placeholder="Telefoonnummer"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-form-group
                      class="spacing-smaller"
                      id="input-group-5"
                      label-for="input-5"
                    >
                      <b-form-textarea
                        id="input-5"
                        v-model="form.remark"
                        required
                        placeholder="Opmerking*"
                      ></b-form-textarea>
                    </b-form-group>
                    <button type="submit" class="pushable">
                      <span class="front">
                        verzenden
                      </span>
                    </button>
                  </b-form>
                </b-col>
              </b-row>
              <!-- <b-card class="mt-3" header="Form Data Result">
                <pre class="m-0">{{ form }}</pre>
              </b-card> -->
            </div>
          </b-col>
        </b-row>
      </b-container>
    </transition>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { inView } from "@/utils";
const axios = require("axios");

export default {
  name: "Form",
  components: {},
  props: {},
  data() {
    return {
      toNumber: 0,
      fromNumber: 0,
      start: false,
      form: {
        name: "",
        company: "",
        adress: "",
        email: "",
        phone_number: "",
        remark: "",
      },
    };
  },
  mounted() {
    const element = document.getElementById("form");
    const elementHeight = element.clientHeight;
    window.addEventListener("scroll", () => {
      this.start = inView(element, elementHeight);
    });
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      // alert(JSON.stringify(this.form));

      axios
        .post("https://tobdevelopment.nl/mail.php", this.form)
        .then((Response) => {
          this.$bvToast.toast(Response.data.message, {
            title: "Verzonden",
            autoHideDelay: 5000,
            variant: "success",
          });
          // Reset our form values
          this.form.email = "";
          this.form.name = "";
          this.form.company = "";
          this.form.adress = "";
          this.form.email = "";
          this.form.phone_number = "";
          this.form.remark = "";
        })
        .catch((error) =>
          this.$bvToast.toast(error, {
            title: "Niet verzonden",
            autoHideDelay: 5000,
            variant: "danger",
          })
        );
    },
  },
  computed: {
    animatedNumber: function() {
      return this.fromNumber.toFixed(0);
    },
  },
  watch: {
    toNumber: function(newValue) {
      gsap.to(this.$data, { duration: 5, fromNumber: newValue });
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  .form {
    background: $third-color;
    padding: 2rem;
    border-radius: 0.2rem;
    text-align: left;
    .contact {
      h2,
      form {
        padding-left: 1rem;
      }
    }
    .line {
      border-right: solid #fff 1px;
    }
    p {
      display: flex;
      align-items: center;
      i {
        padding-right: 1rem;
        color: $secondary-color;
        font-size: 2rem;
      }
      a {
        color: #fff;
        &:hover {
          color: #fff;
        }
      }
    }
    .form-control {
      background: $third-color;
      color: #fff;
      border: none;
      border-bottom: 1px solid #fff;
      &:focus {
        background: $third-color;
        border-bottom: 1px solid $secondary-color;
        box-shadow: none;
        transition: border-color 0.5s ease-in-out;

        color: #fff;
      }
      &::placeholder {
        color: gray;
      }
    }
    .btn-primary {
      background: $secondary-color;
      border-color: $secondary-color;
      border-radius: 0;
      &:hover {
        background: #fff;
        border-color: #fff;
        color: $secondary-color;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  section {
    .form {
      .contact {
        h2,
        form {
          padding-left: 0;
        }
      }
      .line {
        border-right: none;
      }
    }
  }
}
</style>
