<template>
  <div id="app">
    <section class="splash">
      <Logo class="splash-logo" alt="logo" />
    </section>
    <div id="header-img" class="header-img">
      <div class="header-logo">
        <Logo @click="easterEgg" id="logo" class="logo" alt="logo" />
      </div>
      <div class="header-text">
        <div class="header-text-wrapper">
          <a class="bounce-6" @click="scrolling('about')"><h2>Over mij</h2> </a>
        </div>
      </div>
    </div>
    <About class="block" />
    <Services class="block" />
    <Projects class="block" />
    <Form class="block" />
    <Footer />
  </div>
</template>
<script>
import About from "@/views/About";
import Services from "@/views/Services";
import Projects from "@/views/Projects";
import Form from "@/views/Form";
import { scrolling } from "@/utils";
import Footer from "@/components/Footer.vue";
import Logo from "@/assets/images/Logo-Tob-development.svg";

export default {
  me: "App",
  components: {
    About,
    Services,
    Projects,
    Form,
    Footer,
    Logo,
  },
  data() {
    return {
      start: false,
      startBounceIn: false,
    };
  },
  created() {},
  methods: {
    scrolling,
    easterEgg() {
      console.log("easter egg");
      const logo = document.getElementById("logo").querySelector(".st1");
      logo.style.cssText = "animation: rotate 2s ";
    },
  },
};
</script>

<style lang="scss">
body {
  animation: overflow-hidden 3s;
  font-family: capitol, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}
#app {
  font-family: capitol, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  overflow-x: hidden;
  background-color: $primary-color;
}

// Button
button.pushable {
  background: #a21917;
  border-radius: 0.4rem;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  .front {
    display: block;
    padding: 12px 42px;
    border-radius: 0.4rem;
    font-size: 1.25rem;
    background: $secondary-color;
    color: white;
    transform: translateY(-6px);
  }
  &:active .front {
    transform: translateY(-2px);
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.header {
  &-logo {
    border-bottom: 10px solid $primary-color;
    position: relative;
    display: block;
    height: 0px;
    width: 100%;
    animation: border-width 1s 2s;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-top: 250px solid $primary-color;
      border-left: 250px solid transparent;
      border-right: 250px solid transparent;
    }
    .logo {
      position: relative;
      width: 165px;
      margin: 0 auto;
    }
  }
  &-img {
    overflow: hidden;
    display: inline-block;
    height: 100vh;
    width: 100%;
    background-image: url("assets/images/background5.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
  }
  &-text {
    position: absolute;
    color: #ffffff;
    bottom: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 50%;

    background: linear-gradient(transparent, $primary-color);
    &-wrapper {
      animation: show 1s 2s;
      a {
        color: $secondary-color;
        display: inline-block;
        margin-bottom: 4em;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 0;
          height: 0;
          top: 35px;
          border-top: 50px solid $secondary-color;
          border-left: 50px solid transparent;
          border-right: 50px solid transparent;
        }
        h2 {
          font-weight: bold;
        }
        .arrow {
          width: 3em;
          filter: invert(19%) sepia(78%) saturate(5120%) hue-rotate(17deg)
            brightness(97%) contrast(111%);
        }
      }
    }
  }
}

.block {
  min-height: 100vh;
  position: relative;
  padding-bottom: 6rem;
  &:last-of-type {
    min-height: 94vh;
  }
  &-spacing {
    padding-top: 6rem;
    padding-bottom: 2rem;
  }
}

a.bounce-7 {
  color: $secondary-color;
  display: inline-block;
  margin-bottom: 4em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    top: 35px;
    border-top: 50px solid $secondary-color;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
  }
  h2 {
    font-weight: bold;
  }
  .arrow {
    width: 3em;
    filter: invert(19%) sepia(78%) saturate(5120%) hue-rotate(17deg)
      brightness(97%) contrast(111%);
  }
}

.slide-fade-right-enter-active {
  transition: all 3s ease;
}
.slide-fade-right-enter {
  transform: translateX(100px);
  opacity: 0;
}

.bounce-enter-active {
  animation: fly-in 0.5s;
}
.bounce-leave-active {
  animation: fly-in 0.5s reverse;
}

a {
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

.bounce-6 {
  animation-name: bounce-6;
  animation-timing-function: ease;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-delay: 3s;
}
.bounce-7 {
  animation-name: bounce-6;
  animation-timing-function: ease;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.splash {
  position: fixed;
  background-color: $primary-color;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  opacity: 0;
  display: flex;
  animation: removeBackground 3s;
  .splash-logo {
    animation: fadeIn 2s;
    width: 165px;
    top: 0;
    position: absolute;
    .st1 {
      animation: rotate 2s linear;
    }
  }
}

@media screen and (max-width: 575px) {
  .header {
    &-logo {
      &:before {
        border-top: 150px solid $primary-color;
        border-left: 150px solid transparent;
        border-right: 150px solid transparent;
      }
      .logo {
        width: 100px;
        top: 0.5rem;
      }
    }
  }
  .splash {
    .splash-logo {
      top: 0.5rem;
      width: 100px;
      animation: fadeInMobile 2s;
    }
  }
}

// Keyframes

@keyframes bounce-6 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(0);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fly-in {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* The animation code */
@keyframes fadeIn {
  0% {
    opacity: 0;
    width: 400px;
    top: 25%;
  }
  50% {
    opacity: 1;
    top: 25%;
    width: 400px;
  }
  100% {
    top: 0;
    width: 165px;
  }
}

/* The animation code */
@keyframes fadeInMobile {
  0% {
    opacity: 0;
    width: 200px;
    top: 25%;
  }
  50% {
    opacity: 1;
    top: 25%;
    width: 200px;
  }
  100% {
    top: 0.5rem;
    width: 100px;
  }
}

@keyframes removeBackground {
  0% {
    display: flex;
    opacity: 1;

    width: 100%;
    height: 100vh;
  }
  66% {
    display: flex;
    opacity: 1;
  }
  99% {
    opacity: 0;
    width: 100%;
    height: 100vh;
  }
  100% {
    opacity: 0;
    height: 0;
    width: 0;
  }
}

@keyframes rotate {
  0% {
    transform-box: fill-box;
    transform-origin: center;
    transform: rotate(0deg);
  }
  100% {
    transform-box: fill-box;
    transform-origin: center;
    transform: rotate(180deg);
  }
}

@keyframes overflow-hidden {
  0% {
    overflow: hidden;
  }
  100% {
    overflow: hidden;
  }
}

@keyframes border-width {
  0% {
    width: 500px;
  }

  100% {
    width: 100%;
  }
}

@keyframes show {
  0% {
    opacity: 0;
    margin-bottom: 400px;
  }
  50% {
  }
  100% {
    opacity: 1;
    margin-bottom: 0;
  }
}
</style>
