export const serviceData = {
  "Website bouw": [
    {
      title: "Wil je een website laten bouwen",
      content:
        "dan ben jij bij mij aan goeie adress, Ik help je graag om tot een goed eind resultaat te komen.",
      rules: [
        "Neem contact op via mail: info@tobdevelopment.nl",
        "of bel naar +31 6 18695560",
      ],
    },
  ],
  "App bouw": [
    {
      title: "Apps laten bouwen is ook mogelijk",
      content: "Ik kan apps gebouwen voor zowel IOS als ANDROID",
      rules: [
        "Neem contact op via mail: info@tobdevelopment.nl",
        "of bel naar +31 6 18695560",
      ],
    },
  ],
  Hosting: [
    {
      title: "Single",
      content:
        "Heb je nog geen of een kleinere website, en wil je alvast kunnen mailen onder je eigen domein? Kies dan ons meest eenvoudige pakket, welke je later altijd kunt upgraden.",
      prijs: "<span>€37,50</span>/jaar",
      rules: [
        "<span>1 GB</span> serverruimte",
        "Minimale performance <span>256 MB</span> geheugen",
        "Niet geschikt voor Wordpress, Joomla, Drupal",
        "<span>20</span> mailboxen",
        "<span>1</span> website hosten",
        "SSL beveiliging",
      ],
    },
    {
      title: "Standard",
      content:
        "Je websites online. Eenvoudig, met persoonlijke service. Speciaal voor de particulier of startende ondernemer die geen omkijken naar zijn of haar websites wil.",
      prijs: "<span>€75,-</span>/jaar",
      rules: [
        "<span>5 GB</span> serverruimte",
        "Standaard performance <span>512 MB</span> geheugen",
        "Geschikt voor WordPress, Joomla, Drupal",
        "<span>20</span> mailboxen",
        "<span>3</span> websites hosten",
        "SSL beveiliging",
      ],
    },
    {
      title: "Suite",
      content:
        "Meer controle over je zaken? Host nog meer websites binnen 1 pakket. Voor de ondernemer die op zoek is naar meer veiligheid, snelheid en mogelijkheden.",
      prijs: "<span>€150,-</span>/jaar",
      rules: [
        "<span>10 GB</span> serverruimte",
        "Standaard performance <span>512 MB</span> geheugen",
        "uitermate voor WordPress, Joomla, Drupal",
        "<span>99</span> mailboxen",
        "<span>6</span> websites hosten",
        "SSL beveiliging",
      ],
    },
    {
      title: "Presidential suite",
      content:
        "Dit pakket heeft extra veel serverruimte, performance en aantal websites. Dit maakt het de perfecte keuze voor grotere websites en projecten.",
      prijs: "<span>€300,-</span>/jaar",
      rules: [
        "<span>25 GB</span> serverruimte",
        "Maximale performance <span>512 MB</span> geheugen",
        "uitermate voor WordPress, Joomla, Drupal",
        "<span>99</span> mailboxen",
        "<span>9</span> websites hosten",
        "SSL beveiliging",
      ],
    },
  ],
  "E-mail": [
    {
      title: "Mail XXL",
      prijs: "<span>€70,-</span>/jaar per 10 GB",
      rules: [
        "<span>99</span> mailboxen zonder opslaglimiet",
        "IMAP-, POP- & webmail-toegang",
        "Bescherming tegen spam & virussen",
        "Dagelijkse backup",
      ],
    },
  ],
  "E-mailmarketing": [
    {
      title: "10.000 MAILS PER MAAND",
      prijs: "<span>€300,-</span>/jaar",
      rules: [
        "Nieuwsbrieven versturen",
        "Transactionele e-mail",
        "Marketing automation",
      ],
    },
    {
      title: "40.000 MAILS PER MAAND",
      prijs: "<span>€600,-</span>/jaar",
      rules: [
        "Nieuwsbrieven versturen",
        "Transactionele e-mail",
        "Marketing automation",
      ],
    },
    {
      title: "40.000 MAILS PER MAAND",
      prijs: "<span>€900,-</span>/jaar",
      rules: [
        "Nieuwsbrieven versturen",
        "Transactionele e-mail",
        "Marketing automation",
      ],
    },
  ],
  "Domein aanvraag": [
    {
      title: "*.nl of *.be domein",
      prijs: "<span>€12,-</span>/jaar",
      rules: ["Gratis SSL er bij"],
    },
    {
      title: "*.com domein",
      prijs: "<span>€22,-</span>/jaar",
      rules: ["Gratis SSL er bij"],
    },
    {
      title: "*.* domein",
      content: "stuur een mail info@tobdevelopment.nl voor andere soort domein",
      prijs: "<span>?,-</span>/jaar",
      rules: ["Gratis SSL er bij"],
    },
  ],
};
