<template>
  <section id="about">
    <transition name="bounce">
      <b-container class="block-spacing" v-if="start">
        <b-row align-h="center">
          <b-col cols="12">
            <h2 class="spacing-small">Over mij</h2>
            <p>
              Ik ben Tobias van der Voorn, een 22-jarige jongen geboren en
              getogen in Nieuwkoop. Vanaf jongs af aan ben ik al veel bezig met
              computer gerelateerde zaken. Dit is dan ook waar mijn passie voor
              development vandaan komt. Ik neem je graag mee hoe mijn bedrijf
              Tobdelopment tot stand is gekomen en waar ik voor sta.
            </p>
            <p>
              Op 01-10-2020 heb ik me ingeschreven bij de Kamer van Koophandel
              met de naam: Tobdevelopment Mijn bijnaam is Tob (Tobias) en
              uiteindelijk streef ik natuurlijk naar een "top" resultaat. Daar
              komt de naam Tobdevelopment vandaan. Ik heb gekozen voor de
              kleuren donkerblauw en rood voor mijn huisstijl. Dit omdat
              donkerblauw een kalme kleur is die ook veel gebruikt wordt bij
              programmeurs. Rood is een kleur die ik associeer met kracht en
              passie en daarom heb ik deze twee kleuren bij elkaar gevoegd.
            </p>
            <p>
              Mijn hele leven lang ben ik al geinteresseerd in hoe applicaties
              in elkaar zitten, waardoor ik op een gegeven moment mijzelf erin
              ben gaan verdiepen. Hierdoor kwam ik terecht op de open dag van
              ROC Leiden voor de studie applicatieontwikkelaar. Na deze open dag
              wist ik gelijk dat dit de juiste studie voor mij was. Mijn gehele
              studie heb ik verschillende stageplekken gehad waardoor ik goed
              heb kunnen orienteren wat ik nou echt het leukste vond om te doen.
              Uiteindelijk kwam ik tot de conclusie dat mijn passie echt bij
              development lag. Toen ik afgestudeerd was en begon met werken in
              de ICT wereld, begon de ambitie om een eigen bedrijf op te starten
              in developmemt steeds meer te groeien.
            </p>
            <p class="spacing-smaller">
              Mijn kernwaarden binnen Tobdevelopment zijn flexibaliteit,
              eerlijkheid en innovatie. Omdat ik makkelijk in de omgang ben, wil
              ik dit ook graag uitstralen op mijn klanten. Ik zie Tobdevelopment
              als prioriteit en daarbij dus ook jou als klant. Ook zal ik altijd
              eerlijk zijn over wat er wel of niet mogelijk is. Op deze manier
              kunnen we samen sparren en uiteindelijk met een compleet plan
              komen. Ik wil graag vernieuwend blijven en mee bewegen met de
              markt. Er is namelijk altijd ruimte voor technische verbetering.
            </p>
          </b-col>
        </b-row>
        <b-row class="spacing-big">
          <b-col cols="12" md="4">
            <h3>Communicatie</h3>
            <p class="spacing-smaller">
              ik vind het belangrijk om altijd goed contact te hebben met de
              klant zodat ik kan voldoen aan de verwachting van de klant en
              hierdoor het beste resultaat kan leveren.
            </p>
          </b-col>

          <b-col cols="12" md="4">
            <h3>Service</h3>
            <p class="spacing-smaller">
              tijdens het proces van het bouwen van de website is de klant
              altijd welkom voor vragen en hecht ik veel waarde aan
              tussenevaluaties om de klant op de hoogte te houden van de stand
              van zaken.
            </p>
          </b-col>
          <b-col cols="12" md="4">
            <h3>Kwaliteit</h3>
            <p class="spacing-smaller">
              als we het eindresultaat hebben behaald, zijn er nog altijd
              mogelijkheden om het eindresultaat te verbeteren.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </transition>
    <a class="bounce-7" @click="scrolling('services')"><h2>Diensten</h2> </a>
  </section>
</template>

<script>
import { inView, scrolling } from "@/utils";

export default {
  name: "About",
  components: {},

  props: {},
  data() {
    return {
      start: false,
    };
  },
  mounted() {
    const element = document.getElementById("about");
    const elementHeight = element.clientHeight;
    window.addEventListener("scroll", () => {
      this.start = inView(element, elementHeight);
    });
  },
  methods: {
    scrolling,
  },
};
</script>
