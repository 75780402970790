<template>
  <footer id="footer" class="text-muted">
    <h6><span>Tobdevelopment</span> ©Copyright 2020</h6>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  // props: {},
  // data() {},
  created() {
    window.addEventListener("scroll", this.checkScrolledDown);
  },
  methods: {
    checkScrolledDown() {
      const footer = document.getElementById("footer");
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 20
      ) {
        // console.log("down");
        footer.classList.remove("hidden");
      } else {
        footer.classList.add("hidden");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#footer {
  padding: 25px;
  background-color: $third-color;
  transition: opacity 0.5s;
  h6 {
    margin: 0px;
    span {
      color: $secondary-color;
    }
  }
}
</style>
